$blue: rgb(72, 109, 209)
$blue-hover: rgb(63, 97, 189)
$white-blue: rgb(90, 123, 212)
$whiter-blue: rgb(109, 136, 216)
$grey-blue: #557a95

$mui-primary: #3f51b5
$mui-secondary: #f50057

$orange: orange

$red: rgb(196, 37, 37)
$red-dim: rgb(207, 71, 71)
$red-hover: rgb(177, 33, 33)
$red-active: rgb(158, 29, 29)

$dark-red: rgb(119, 24, 24)
$dark-red-hover: rgb(104, 20, 20)
$dark-red-active: rgb(87, 17, 17)

$green: #159c2b

$not-white: rgb(250, 250, 250)
$almost-white: rgb(241, 241, 241)
$basically-white: rgb(235, 235, 235)
$less-white: rgb(230, 230, 230)
$lesser-white: rgb(216, 216, 216)
$grey: rgb(175, 175, 175)
$graphite-hover: rgb(87, 87, 87)
$graphite: rgb(73, 73, 73)

$font: montserrat, Helvetica, Arial, sans-serif

$smaller-font-size: 12px
$small-font-size: 15px
$normal-font-size: 18px
$bigger-font-size: 21px
$header-font-size: 25px
$big-header-font-size: 30px
$huge-font-size: 40px

$mobile-width: 0px
$tablet-width: 768px
$desktop-width: 1280px

@mixin mobile
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px})
    @content

@mixin tablet
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px})
    @content

@mixin mobile_tablet
  @media (min-width: #{$mobile-width}) and (max-width: #{$desktop-width - 1px})
    @content

@mixin desktop
  @media (min-width: #{$desktop-width})
    @content
