//$backgroundImage: url('../images/home/Bg-Dektop-Lista@2x.png'  )

.relleno
    margin-bottom: 20%
    width: 200%
.body_primer_torneo
    margin-top: 2.5%
body
    //background-image: $backgroundImage
    background-size: cover
    background-repeat: no-repeat

.container_tablas
    max-width: 100%
    margin-right: auto
    margin-left: auto
    background-color: #000
    padding-left: 5%
    padding-right: 5%
    box-sizing: border-box

.celda_tabla
    margin-bottom: 2%
    width: 100px
.row_table
    font-size: 20

@media only screen and (min-width: 992px) 
    .imagenes_promocion
        float: left
        margin-left: -5%
        margin-top: 2%
        margin-bottom: 2%
        width: 110%
        box-sizing: border-box
    .left_area_torneo
        width: 10% 
        float: left
        padding-right: 5%
    .main_area_torneo
        .main_area_tabla
            width: 70% 
            float: left
            box-sizing: border-box
            margin-left: -2%
            a
                padding-left: 12%
            .area_tablas
                width: 90% 
        .right_area_torneo
            width: 25% 
            float: left
            margin-top: -20%
            box-sizing: border-box
            .carousel
                width: 400px
                margin-left: 0%
                .carousel_image
                    width: 400px
        .over_main_area_tabla
            float: left
            width: 80%
            margin-left: 0%
            .buscar_button
                margin-left: 13%
                margin-top: 2%
                margin-bottom: 5%
                width: 50%
                border-radius: 25px
                color: #fff
                background-color: #021d49
                font-weight: bold
                font-size: 150%
                &:hover
                    background-color: #021d49DF
            .buscar_input_div
                width: 100%
                margin-left: 13%
                margin-top: 2%
                .buscar_input
                    box-sizing: border-box
                    width: 50%
                    height: 50px
                    padding-left: 5%
                    border-radius: 25px
                    border: 1px solid #021d49
                    background-color: #021d49AA
                    color: #fff
            .subtitulo_tabla
                display: flex
                justify-content: center
                align-items: center
                box-sizing: border-box
                margin-left: -11%
                width: 100%
                color: #021d49
        .navbar_torneo
            border: 2px solid black
        .nav
            float: left
            .right_navbar
                width: 35%
                margin-left: 160%
                box-sizing: border-box
                .a_navbar
                    text-decoration: none
                    color: #021d49
                    cursor: pointer
    .h4_title
        color: #021d49
        margin-left: 25%
        margin-top: 2%
        
@media only screen and (max-height: 992px) and (max-width: 400px)
    .nav
        margin-left: 8%
    .imagenes_promocion
        float: left
        margin-left: -5%
        margin-top: 0%
        margin-bottom: 10%
        width: 110%
        box-sizing: border-box
    .left_area_torneo
        width: 10% 
        float: left
        margin-left: -8%
        margin-right: 0%
        box-sizing: border-box
    .h4_title
        color: #021d49
        margin-left: 12%
        margin-top: 5%
    .right_area_torneo
        .carousel
            width: 300px
            height: 272px
            margin-left: 3%
            margin-top: 15%
            float: left
            .carousel_image
                width: 300px
    .subtitulo_tabla
        display: flex
        justify-content: center
        align-items: center
        box-sizing: border-box
        margin-left: 0%
        width: 100%
        color: #021d49
    
    .right_navbar
        width: 35%
        margin-left: 25%
        box-sizing: border-box
        .a_navbar
            text-decoration: none
            color: #021d49
            cursor: pointer
    @media only screen and (max-height: 415px) 
        .left_area_torneo
            width: 20% 
            float: left
            margin-left: 0%
            margin-right: 10%
            box-sizing: border-box
        .h4_title
            color: #021d49
            margin-left: 30%
            margin-top: 5%
    .main_area_torneo
        width: 90% 
        float: left
        box-sizing: border-box
        margin-left: 5%
        a
            padding-left: 20%
        .buscar_button
            margin-left: 15%
            margin-top: 5%
            margin-bottom: 5%
            width: 70%
            height: 40px
            border-radius: 25px
            color: #fff
            background-color: #021d49
            font-weight: bold
            font-size: 100%
            &:hover
                background-color: #021d49DF
        .buscar_input_div
            width: 100%
            margin-left: 15%
            margin-top: 5%
            .buscar_input
                box-sizing: border-box
                width: 70%
                height: 40px
                padding-left: 5%
                border-radius: 25px
                border: 1px solid #FFFFFF
                background-color: #FFFFFFAA
        .logo_torneo
            margin-left: -15%
            margin-top: 10%
            box-sizing: border-box
@media only screen and (max-width: 992px) and (max-height: 400px)
    .left_area_torneo
        width: 10% 
        float: left
        margin-left: 0%
        margin-right: 0%
        box-sizing: border-box
    .h4_title
        color: #021d49
        margin-left: 27%
        margin-top: 5%
    .right_area_torneo
        .carousel
            width: 400px
            height: 472px
            margin-left: 20%
            margin-top: 5%
            float: left
            .carousel_image
                width: 400px
    .subtitulo_tabla
        display: flex
        justify-content: center
        align-items: center
        box-sizing: border-box
        margin-left: 0%
        margin-top: 20%
        width: 100%
        color: #021d49
    .nav
        margin-left: 5%
        float: left
        .right_navbar
            width: 35%
            margin-left: 70%
            box-sizing: border-box
            .a_navbar
                text-decoration: none
                color: #021d49
                cursor:pointer
    .main_area_torneo
        width: 90% 
        float: left
        box-sizing: border-box
        margin-left: 5%
        a
            padding-left: 20%
        .buscar_button
            margin-left: 15%
            margin-top: 5%
            margin-bottom: 5%
            width: 70%
            height: 40px
            border-radius: 25px
            color: #fff
            background-color: #021d49
            font-weight: bold
            font-size: 100%
            &:hover
                background-color: #021d49DF
        .buscar_input_div
            width: 100%
            margin-left: 15%
            margin-top: 5%
            .buscar_input
                box-sizing: border-box
                width: 70%
                height: 40px
                padding-left: 5%
                border-radius: 25px
                border: 1px solid #FFFFFF
                background-color: #FFFFFFAA
        .logo_torneo
            margin-left: 0%
            margin-top: 10%
            box-sizing: border-box
        .main_area_tabla
            width: 105% 
            float: left
            box-sizing: border-box
            margin-left: -3%
            a
                padding-left: 0%
            .area_tablas
                margin-left: 0%
                width: 100% 