.imageContainer form
    width: 400px
    height: 300px
    background-color: #eceef24a
    margin: auto
    border-radius: 5px
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3)
    box-sizing: border-box
    margin-bottom: 3rem

.uploadBox
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    font-size: 60px
    box-sizing: border-box
    border-radius: 5px
    img
        height: 100%
        border-radius: 5px

.input_file_image
    display: none

.image_name
    
    fontSize: bold
    width:  100%
.btn
    display: flex
    justify-content: center
    position: relative
    top: 20px
    .button_upload
        margin: auto
        color: #FFF
        background-color: #021d49
        font-weight: 750
        cursor: pointer
        &:hover
                background-color: #021d49DF
        &:disabled
            color: #ffffff80