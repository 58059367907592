//$backgroundImage: url('../images/home/Bg-Dektop-Lista@2x.png'  )
@media only screen and (min-width: 992px)
    .body_inicio
        border: 1 px solid black
        margin-bottom: 0%
        margin-top: 0%
    .container
        max-width: 100%
        margin-right: auto
        margin-left: auto
        background-color: #000
        padding-left: 0%
        padding-right: 0%
        margin-top: 0%
        box-sizing: border-box

    .main_area
        
        width: 100%
        box-sizing: border-box
        padding-left: 0%
        padding-right: 0%
        float: left

        .div_upper_logo
            width: 30%
            margin: auto
            box-sizing: border-box
            float: left

        h4
            margin-left: 13%
            margin-top: 5%
            margin-bottom: 5%
            color: black
        .inicio_button
            margin-left: 12%
            margin-top: 5%
            width: 80%
            border: 1px solid #021d49
            border-radius: 25px
            color: #fff
            background-color: #021d49
            font-weight: bold
            font-size: 150%
            &:hover
                background-color: #021d49DF
        .main_button
            margin-top: 0%
            padding-left: 15%
            padding-right: 15%
        .promotion_image
            margin-top: 25%
            padding-left: 10%
            padding-right: 10%
            .grid_productos
                height: 80px
                display: flex
                justify-content: start
                align-items: center
                z-index: 11
                .imagenes_banner
                    width: 100%
                    height: 100%
        .imagenes_promocion
            margin-left: -10%
            margin-top: 5%
            width: 110%
            box-sizing: border-box
        .logo_main_area
            margin-right: auto
            margin-left: auto
            width: 20%
            box-sizing: border-box
        .relleno
            box-sizing: border-box
    html
        //background-color: black
//Para celular en vertical
@media only screen and (max-height: 992px) and (max-width: 400px)
    .body_inicio
        margin-bottom: 50px
        margin-right: auto
        margin-left: auto
    .container
        max-width: 100%
        margin-right: auto
        margin-left: auto
        background-color: #000
        padding-left: 5%
        padding-right: 5%
        box-sizing: border-box
        margin-bottom: 10%
    .main_area
        float: left
        width: 100% 
        padding-right: 0%
        margin-top: -10%
        margin-bottom: 0%
        .div_upper_logo
            width: 80%
            margin-left: 8%
            box-sizing: border-box
            float: left
        h4
            margin-left: 5%
            margin-top: 5%
            margin-bottom: 5%
            color: black
        .inicio_button
            margin-left: -5%
            margin-top: 10%
            width: 100%
            border: 1px solid #021d49
            border-radius: 25px
            color: #fff
            background-color: #021d49
            font-weight: bold
            font-size: 100%
            &:hover
                background-color: #021d49DF
        .promotion_image
            margin-top: -15%
            padding-left: 0%
            padding-right: 0%
        a
            margin-left: 5% 
    .footer_inicio
        width: 100%
        padding-left: 0%
        margin-right: auto
        margin-left: auto
        margin-top: 2%
        box-sizing: border-box
    .terminos
        width: 100%
        padding-left: 0%
        margin-top: 5%
        box-sizing: border-box
        color: black
    html
        //background-color: black
    .imagenes_promocion
        margin-left: -5.5%
        margin-top: 10%
        width: 111%
        box-sizing: border-box
    .image_logo_colombia
        margin-left: 25%
        margin-top: 5%
        margin-bottom: 5%
    .grid_productos
        display: flex
        justify-content: start
        align-items: center
        margin-top: 30%
        .imagenes_banner
            width: 80%
            height: 40%
    /* Small devices (portrait tablets and large phones, 600px and up) */
    //@media only screen and (min-width: 600px) 

    /* Medium devices (landscape tablets, 768px and up) */
    //@media only screen and (min-width: 768px)

    /* Large devices (laptops/desktops, 992px and up) */

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    //@media only screen and (min-width: 1200px)
@media only screen and (max-width: 992px) and (max-height: 400px)
    .grid_productos
        height: 80px
        display: flex
        justify-content: start
        align-items: center
        z-index: 11
        margin-top: 30%
        .imagenes_banner
            width: 100%
            height: 70%
    .inicio_button
        margin-left: 12%
        margin-top: 5%
        width: 80%
        border: 1px solid #021d49
        border-radius: 25px
        color: #fff
        background-color: #021d49
        font-weight: bold
        font-size: 150%
        &:hover
            background-color: #021d49DF
    .div_upper_logo
        width: 40%
        margin-bottom: 2%
        margin-left: 30%
        float: left
        
        