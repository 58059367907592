.button_excel
    display: flex
    padding-right: 2rem
    color: #FFF
    background-color: #021d49
    font-size: 750
    cursor: pointer
    &:hover
        background-color: #021d49DF
    &:disabled
        color: #ffffff80